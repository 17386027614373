/**
 This function is mainly used to determine if the starring button should
 appear for a specific content type (i.e whiteboards/databases and any future content types)
*/
export const useIsStarringEnabled = (contentType: string | null | undefined) => {
	return (
		contentType === 'whiteboard' ||
		contentType === 'database' ||
		contentType === 'embed' ||
		contentType === 'folder'
	);
};
