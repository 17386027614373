import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N0, N80 } from '@atlaskit/theme/colors';
import Button from '@atlaskit/button/standard-button';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CustomAppearanceButton = styled(Button)<{ isSelected?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: `${(props) =>
			props.isSelected
				? token('color.text.selected', N0)
				: token('color.text.subtlest', N80)} !important`,
		minWidth: '32px',
	},
});
