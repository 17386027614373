import type { FC } from 'react';
import React, { useContext, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ShareIcon from '@atlaskit/icon/glyph/share';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import { ShareButtonLiteLoader } from '@confluence/share';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { useSessionData } from '@confluence/session-data';

import { CustomAppearanceButton } from '../PresentationalComponents';
import { PageCardContext } from '../../PageCardContext';
import { useMenuState, usePageCardAnalyticsEvents } from '../../hooks';

import { QuickActionsEnum } from './QuickActionsEnum';
import { ActionContainer } from './ActionContainer';

const i18n = defineMessages({
	share: {
		id: 'page-card.quick-actions.share.page',
		defaultMessage: 'Share',
		description: 'Share action in page card quick actions',
	},
	shareLabel: {
		id: 'page-card.quick-actions.share.aria-label',
		defaultMessage: 'Share {title}',
		description: 'Aria label for share icon button for each page',
	},
});

type ShareTriggerProps = {
	onClick: () => void;
	isSelected: boolean;
	triggerProps;
};
const ShareTrigger: FC<ShareTriggerProps> = ({ onClick, isSelected, triggerProps }) => {
	useMenuState(QuickActionsEnum.ShareButton, isSelected);
	const { createAnalyticsEvent } = usePageCardAnalyticsEvents();
	const { type, appearance, title } = useContext(PageCardContext);
	const intl = useIntl();
	const compact = appearance === 'compact-list';

	const handleShareClick = useCallback(() => {
		onClick();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'share',
				attributes: {
					pageType: type,
					originProduct: 'confluence',
				},
			},
		}).fire();
	}, [onClick, createAnalyticsEvent, type]);

	return (
		<Tooltip content={intl.formatMessage(i18n.share)} position="top" hideTooltipOnClick>
			<div>
				<CustomAppearanceButton
					spacing={compact ? 'compact' : 'default'}
					onClick={handleShareClick}
					{...triggerProps}
					appearance="subtle"
					isSelected={isSelected}
					aria-label={intl.formatMessage(i18n.shareLabel, {
						title: title.text,
					})}
					iconBefore={<ShareIcon label={intl.formatMessage(i18n.share)} />}
				/>
			</div>
		</Tooltip>
	);
};

export const ShareButton: FC = () => {
	const {
		id,
		title: { text },
		links: { webui },
	} = useContext(PageCardContext);
	const { userId } = useSessionData();

	return (
		<ActionContainer>
			<ShareButtonLiteLoader
				title={text}
				link={`${window.location.origin}${CONTEXT_PATH}${webui}`}
				contentType="page"
				contentId={id}
				userId={userId || ''}
				renderCustomTriggerButton={({ onClick, isSelected }, { ...triggerProps }) => (
					<ShareTrigger onClick={onClick} isSelected={!!isSelected} triggerProps={triggerProps} />
				)}
			/>
		</ActionContainer>
	);
};
