import type { FC } from 'react';
import React, { useContext } from 'react';

import { THREE_HUNDRED_SIXTY_FIVE_DAYS_IN_SECONDS } from '@confluence/automation-discovery/entry-points/constants';
import { LazyAutomationDiscoveryHoverPageTitleMessage } from '@confluence/automation-discovery/entry-points';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import { useSpaceKey } from '@confluence/space-utils';

import { PageCardContext } from '../PageCardContext';

export const getIsPageUpdatedOlderThan365Days = (lastModifiedDate): boolean => {
	const getPageUpdatedInSeconds = (lastModifiedRaw): number => {
		const lastModifiedDate = new Date(lastModifiedRaw);
		return (Date.now() - lastModifiedDate.getTime()) / 1000;
	};

	return getPageUpdatedInSeconds(lastModifiedDate) > THREE_HUNDRED_SIXTY_FIVE_DAYS_IN_SECONDS;
};

type AutomationDiscoveryMessageProps = {
	shouldUseAutomationDiscovery?: boolean;
};
const AutomationDiscoveryMessageInner: FC<AutomationDiscoveryMessageProps> = ({
	shouldUseAutomationDiscovery,
}) => {
	const {
		status,
		lastAction: { when },
		type,
	} = useContext(PageCardContext);
	const spaceKey = useSpaceKey();

	const shouldRender =
		shouldUseAutomationDiscovery &&
		type === 'page' &&
		status !== 'draft' &&
		getIsPageUpdatedOlderThan365Days(when);

	return (
		<>{shouldRender && <LazyAutomationDiscoveryHoverPageTitleMessage spaceKey={spaceKey} />}</>
	);
};

export const AutomationDiscoveryMessage = withTransparentErrorBoundary({
	attribution: Attribution.AUTOMATION_FOR_CONFLUENCE,
})(AutomationDiscoveryMessageInner);
