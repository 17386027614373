import type { FC } from 'react';
import React, { useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { css } from '@compiled/react';

import Lozenge, { type ThemeAppearance } from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';

import { useIsBoldPageTreeExperimentEligible } from '@confluence/page-utils/entry-points/unreadPages';

import type { Status } from '../card-types';
import { PageCardContext } from '../PageCardContext';

type StatusType = 'unpublished' | 'draft' | 'unread';

const i18n = defineMessages({
	unpublished: {
		id: 'page-card.lozenge.unpublished',
		defaultMessage: 'unpublished changes',
		description: 'When you last edited the page.',
	},
	draft: {
		id: 'page-card.lozenge.draft',
		defaultMessage: 'draft',
		description: 'When you last edited the page.',
	},
	unread: {
		id: 'page-card.lozenge.unread',
		defaultMessage: 'new page',
		description: "Label text indicating that a page is new and hasn't yet been viewed by the user.",
	},
});

const columnSpacingStyles = css({
	marginTop: token('space.200', '16px'),
});

type StatusLozengeProps = {
	isInColumn?: boolean;
};

function getStatusType(isUnread?: boolean, status?: Status): StatusType | null {
	if (status === 'draft' || status === 'unpublished') {
		return status;
	}
	if (isUnread) {
		return 'unread';
	}
	return null;
}

function getLozengeAppearance(type: StatusType): ThemeAppearance {
	switch (type) {
		case 'unpublished':
		case 'draft':
			return 'default';
		case 'unread':
			return 'new';
	}
}

export const StatusLozenge: FC<StatusLozengeProps> = ({ isInColumn }) => {
	const { status, isUnread } = useContext(PageCardContext);
	const statusType = getStatusType(isUnread, status);
	const isUnreadPagesEnabled = useIsBoldPageTreeExperimentEligible();

	if (!statusType || (statusType === 'unread' && !isUnreadPagesEnabled)) {
		return null;
	}

	const appearance = getLozengeAppearance(statusType);

	return status ? ( // TODO: css prop is still typed for emotion
		<div css={isInColumn && (columnSpacingStyles as any)}>
			<Lozenge appearance={appearance}>
				<FormattedMessage {...i18n[statusType]} />
			</Lozenge>
		</div>
	) : null;
};
