import gql from 'graphql-tag';

export const PageCardStarMutation = gql`
	mutation PageCardStar($id: ID!) {
		favouritePage(favouritePageInput: { pageId: $id }) {
			content {
				id
				metadata {
					currentuser {
						favourited {
							isFavourite
						}
					}
				}
			}
		}
	}
`;

export const PageCardUnstarMutation = gql`
	mutation PageCardUnstar($id: ID!) {
		unfavouritePage(favouritePageInput: { pageId: $id }) {
			content {
				id
				metadata {
					currentuser {
						favourited {
							isFavourite
						}
					}
				}
			}
		}
	}
`;
