import type { FC } from 'react';
import React, { useContext } from 'react';
import { styled } from '@compiled/react';

import { QuickActionsContext } from './QuickActions';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HiddenAction = styled.span({
	transition: 'ease-in 150ms',
	opacity: 'var(--show-action)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StaticAction = styled.span({
	zIndex: 1,
});

export const ActionContainer: FC<{ children?: React.ReactNode }> = ({ children }) => {
	const { hidden } = useContext(QuickActionsContext);
	const Container = hidden ? HiddenAction : StaticAction;
	return <Container>{children}</Container>;
};
