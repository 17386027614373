import type { FC } from 'react';
import React, { useContext, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import EditIcon from '@atlaskit/icon/glyph/edit';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import { getURLBypassingResumeDraftAction } from '@confluence/content-utils';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { EditContentButtonPreloader } from '@confluence/edit-button';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { END } from '@confluence/navdex';
import { useEditPageLoadingActions } from '@confluence/load-edit-page/entry-points/EditPageLoadingContext';
import { PAGECARD_EDIT_BUTTON_CLICK } from '@confluence/load-edit-page/entry-points/constants';

import { PageCardContext } from '../../PageCardContext';
import { CustomAppearanceButton } from '../PresentationalComponents';
import { usePageCardAnalyticsEvents } from '../../hooks';

import { ActionContainer } from './ActionContainer';

const i18n = defineMessages({
	edit: {
		id: 'page-card.quick-actions.edit.page',
		defaultMessage: 'Edit',
		description: 'Open page in editor from quick actions',
	},
	editLabel: {
		id: 'page-card.quick-actions.edit.aria-label',
		defaultMessage: 'Edit {title}',
		description: 'Aria label for edit icon button for each page',
	},
});

export const EditButton: FC = () => {
	const {
		id,
		space,
		type,
		links: { editui },
		appearance,
		title,
		editorVersion,
	} = useContext(PageCardContext);
	const { loadEditor } = useEditPageLoadingActions();
	const intl = useIntl();
	const spaceKey = space?.spaceKey;

	const isOnEditRoute = useIsEditorPage();

	const compact = appearance === 'compact-list';

	// @ts-ignore editorVersion is not typed correctly in the PageCardContext as here it comes
	// and is accessed a fragment of the ADF document rather than a string
	const editorVersionValue = editorVersion?.nodes?.[0]?.value?.replace(/['"]+/g, '');

	const { createAnalyticsEvent } = usePageCardAnalyticsEvents();
	const fireClickAnalytics = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'clicked',
				actionSubject: 'editButton',
				attributes: {
					navdexPointType: END,
					pageType: type,
					isOnEditRoute,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, type, isOnEditRoute]);

	const resumeDraftBypass = (urlPath, spaceKey, contentType, contentId): string =>
		getURLBypassingResumeDraftAction({
			url: urlPath || '',
			spaceKey: spaceKey || '',
			contentType,
			editorVersion: editorVersionValue,
			contentId: contentId || '',
			noContextPath: false,
		});

	const handleClick = useCallback(
		(e: any) => {
			fireClickAnalytics();
			e.preventDefault();
			e.stopPropagation();
			loadEditor({
				contentId: id,
				spaceKey,
				redirectUrl: `${CONTEXT_PATH}${editui}`,

				isFabricSupported: editorVersionValue === 'v2',
				isEmbeddedContent: false,
			});
		},
		[fireClickAnalytics, loadEditor, id, spaceKey, editui, editorVersionValue],
	);

	return (
		<ActionContainer>
			<EditContentButtonPreloader
				key={id}
				contentId={id}
				spaceKey={spaceKey}
				isEmbeddedEditor={false}
				editSource={PAGECARD_EDIT_BUTTON_CLICK}
			>
				<Tooltip content={intl.formatMessage(i18n.edit)} position="top" hideTooltipOnClick>
					<CustomAppearanceButton
						spacing={compact ? 'compact' : 'default'}
						onClick={handleClick}
						href={resumeDraftBypass(`${CONTEXT_PATH}${editui}`, spaceKey, type, id)}
						appearance="subtle"
						aria-label={intl.formatMessage(i18n.editLabel, {
							title: title.text,
						})}
						iconBefore={<EditIcon label={intl.formatMessage(i18n.edit)} />}
					/>
				</Tooltip>
			</EditContentButtonPreloader>
		</ActionContainer>
	);
};
