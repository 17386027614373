import { useContext, useEffect } from 'react';

import { PageCardContext } from '../PageCardContext';
import { QuickActionsEnum } from '../Items/QuickActions/QuickActionsEnum';

export const useMenuState = (menuItem, isSelected) => {
	const { setIsMenuOpen, setSelectedMenuItems, selectedMenuItems } = useContext(PageCardContext);
	useEffect(() => {
		setSelectedMenuItems({ ...selectedMenuItems, [menuItem]: isSelected });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelected]);

	useEffect(() => {
		if (
			selectedMenuItems[QuickActionsEnum.WatchButton] ||
			selectedMenuItems[QuickActionsEnum.ShareButton]
		) {
			setIsMenuOpen(true);
		} else {
			setIsMenuOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMenuItems]);
};
